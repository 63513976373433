
import { useNavigate } from "react-router-dom";
import Header from "../components/home/Header";

const Error = () => {
    const navigate = useNavigate();
    
    return (
        <div>
            <Header/>
            <h1>
                <span>
                    application is not available | you can return later
                </span>
            </h1>

            <div>
                <img src="https://http.cat/images/500.jpg" alt="" />
            </div>
        </div>
    )
}  

export default Error;