import Accordion from 'react-bootstrap/Accordion';
import Header from '../components/home/Header';
import { getAboutBody } from '../components/about/AboutBody';
import { useState } from 'react';

const About = () => {
   
    const text="Проект clandike направлен на помощь студентам, которые по каким либо причинам нуждаются в поддержке товарищей. \
        Полноценный запуск проекта будет в середине-конце ноября. А первые ключи доступа мы уже выдадим в начале ноября 2024 года.";
    
    return (
        <div className='main-content-about'>
            <Header/>
            <div className='about-block'>
                <div className="about-body">
                    {getAboutBody(text)}
                </div>
                <div className="rules-about">
                    <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Правило #1</Accordion.Header>
                                <Accordion.Body>
                                    Создатели проекта не несут ответственность за публикуемые материал другими лицами.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Правило #2</Accordion.Header>
                                <Accordion.Body>
                                    Все, что публикуется на сайте проекта, может быть удалено по просьбе автора материала.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Правило #3</Accordion.Header>
                                <Accordion.Body>
                                    За попытки выложить вредоносные файлы, отчеты не по указанной теме и неприемлемый контент - Бан пожизненно.
                                </Accordion.Body>
                            </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
        
    )
}

export default About;
