import './styles/App.css'
import './styles/Chat.css'
import './styles/Subject.css'
import './styles/About.css'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from './pages/Dashboard'
import Login from './pages/Login';
import Error from './pages/Error';
import Admin from './pages/Admin';
import Subject from './pages/Subject';
import About from './pages/About';


const App = () => {
  
  return (
    <BrowserRouter> 
      <Routes>
        <Route path='/profile' element={<Admin/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/error' element={<Error/>}/>
        <Route path='/subject' element={<Subject/>}/>
        <Route path='/' element={<Dashboard/>}/>
      </Routes>
    </BrowserRouter>
  );  
}




export default App;
