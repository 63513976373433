import Card from 'react-bootstrap/Card';

export function getAboutBody(text){
    return (
        <Card >
            <Card.Body>{text}</Card.Body>
        </Card>
    )
}

