
import React, { useEffect, useState } from "react";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import navigation from "../js/util/navigationHandler";
import Header from "../components/home/Header";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MDBFooter, MDBContainer } from 'mdb-react-ui-kit';

function Login(){
    
    axios.defaults.withCredentials = true;
    
    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const handleChange = (event) => {
        setValues(prev=> ({...prev, [event.target.name]: event.target.value}))
    }

    const handleSubmit =  async (event) => {
        event.preventDefault();
        await axios.post("https://clandike.tech:5000/login", {
            email: values.email,
            password: values.password,
          }).then((res) => {
              if (res.request.status === 200)
                  navigate('/')
          }).catch((err) => {
           	setError("Произошла ошибка. Повторите запрос еще раз.")
       });
    }

    useEffect(()=>{
        axios.get('https://clandike.tech:5000/enter')
            .then(() => {
                navigation(navigate)
            })
            .catch(err => {
                if (err.response.status === 500)
                    navigate('/error')
            })
    }, [])

    // Сохранение email
    const saveEmail = (email) => {
        localStorage.setItem('userEmail', email);
    };
    
    // Получение email
    const getEmail = () => {
        return localStorage.getItem('userEmail');
    };

    return(
        <div className='login-content'>
            <Header/>
            <div className="content-wrapper">
                <div className="main-content-block">
                  
                    <div className="form-content">
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit} className='form'>
	    {/* <div className="header-form">
                                    <div className='name-form'>
                                        Вход
                                    </div>
                                </div>*/}
                                <div className="main-content-form">                    
                                <div>
                                <InputGroup size="sm" className="mb-3">
                                    <Form.Control type="email" name="email"  onChange={handleChange} required placeholder="login"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    />
                                </InputGroup>
                                    {/* <input type="email" name="email"  onChange={handleChange} required placeholder='email' /> */}
                                </div>
                                <div>
                                <InputGroup size="sm" className="mb-3">
                                    <Form.Control type="password" name="password"  onChange={handleChange} required placeholder="password"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    />
                                </InputGroup>
                                    {/* <input type="password" name="password"  onChange={handleChange} required  placeholder='password'/> */}
                                </div>
                                    <Button variant="outline-secondary" type="submit">Войти</Button>{''}
                                </div>
                            </form>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
	    {/*  <Button variant="dark" onClick={()=>navigate('/signup')}>
                                Нет аккаунта? Зарегистрируйтесь
                            </Button>
				*/}
                            {/* <button onClick={() => navigate('/signup')}>
                                Нет аккаунта? Зарегистрируйтесь
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>

               <MDBFooter className='text-center text-white' style={{ backgroundColor: '#21081a' }}>
                    <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        © 2024 Copyright:
                        <a className='text-white' href='http://clandike.tech/'>
                        clandike.tech
                        </a>
                    </div>
                </MDBFooter>
	</div>

    )
}

export default Login;
