import axios from "axios";
import { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import '../styles/Admin.css'
import Header from "../components/home/Header";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Admin = () => {
    axios.defaults.withCredentials = true;
    const navigate = useNavigate();
    const [topics, setTopics] = useState([]);
    const [id, setId] = useState([]);
    const [values, setValues] = useState({
        subject_id: '',
        name: '',
        content:'',
        href:''    });

    const handleChangeFile = (e) => {
        const file = e.target.files[0]; // доступ к файлу
        console.log(file);
        setFile(file); // сохранение файла
    }

    // для хранения загруженного файла
    const [file, setFile] = useState('');
    
    const handleChange = (event) => {
        setValues(prev=> ({...prev, [event.target.name]: event.target.value}))
    }

    const handleSubmit  =  async (event) => {
        console.log(values.subject_id)
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('subject_id', values.subject_id);
        formData.append('name', values.name);
        formData.append('href', 'subject?id='+id);
        formData.append('content', values.content);
        formData.append('id', id);
        await axios.post("https://clandike.tech:5000/api/v1/admin",formData).then((res) => {
          }).catch((err) => {
       });
    }

    useEffect(()=>{
        fetch("https://clandike.tech:5000/api/v1/topicsid")
        .then(res=>res.json())
        .then(
            (result) => {
                setId(result[0].id+1)
            })
        .catch(err=>{
            setId(1);
        });    

        axios.get("https://clandike.tech:5000/api/v1/subjects")

        .then((res)=>{
            setTopics(res.data);
        });

        
        axios.get('https://clandike.tech:5000/enter')
            .then()
            .catch(() => navigate('/login'))
        
    },[]);

    return (
        <div>
            <Header/>
            <div id="admin-form">
            <div className="form-content">
                <div className="form-wrapper">
                        <form onSubmit={handleSubmit} className='form' enctype="multipart/form-data">
                                <div className="header-form">
                                    <div className='name-form'>
                                        Опубликовать Лабу
                                    </div>
                                </div>
                                <div className="main-content-form">                    
                                    <div>
                                        <select name="subject_id" id="subject_id" onClick={handleChange} required placeholder='subject_id'>
                                            <option value="">--Выберите категорию--</option>
                                            {topics.map(e => <option value={e.id} >{e.name}</option>)}
                                        </select>
                                    </div>
                                    <div>
                                     <InputGroup size="sm" className="mb-3">
                                        <Form.Control name="name" type="text" onChange={handleChange} required  placeholder='Название'
                                        aria-label="Small"
                                        aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </InputGroup> 
                                        {/* <input type="text" name="name"  onChange={handleChange} required  placeholder='name'/> */}
                                    </div>
                                    <div>
                                        <textarea name="content" id="content" onChange={handleChange} required>

                                        </textarea>
                                    </div>
                                    <div>
                                        <input type="file" id="file-upload" required onChange={handleChangeFile} accept="application/pdf"/>
                                    </div>
                                    <Button variant="outline-secondary" type="submit"  >Опубликовать статью </Button>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin;
