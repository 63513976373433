import {VscTriangleDown, VscTriangleUp} from "react-icons/vsc";
import React, {Fragment, useState} from "react";
import '../../styles/dashboard.css'

export const TopicsList = (props) => {
    const handleDownload = (filename) => {
        // Создаем ссылку для скачивания
        const link = document.createElement('a');
        link.href = `http://clandike.tech:5000/download/${filename}`;
        link.setAttribute('download', filename); // Указываем имя файла для сохранения
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const TopicElement = (props) => {
        return (<li key={props.name} className={"topic-ul"} >
            <a href={props.href}>{props.name} </a>
            <button onClick={()=>handleDownload(props.filename)} title={props.filename}>Скачать отчёт</button>

        </li>)
    }

    const ChapterParse = (props) => {
        const [open, setOpen] = useState(false)

        const toggle = () => {
            setOpen(!open)
        }

        return (props.element.hasOwnProperty('topics') ?
            <Fragment>
                <li key={props.element.name} className={"topic-ul"} onClick={toggle}>
                    {open ? <VscTriangleUp /> : <VscTriangleDown />} {props.element.name}
                </li>
                {open && <ul key={props.element.name + '_ul'}>
                    {props.element.topics.map(e => <ChapterParse element={e} />)}
                </ul>}

            </Fragment> :
            <TopicElement name={props.element.name} href={props.element.href} filename={props.element.filename}/>
        )
    }

    return (<ul>
        {props.hierarchy.map(e => <ChapterParse element={e}/>)}
    </ul>)
}
