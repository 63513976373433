import GrowExample from "../dashboard/Spinner";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {

    return (
        <header>
            <div className="content-header">
                    <Navbar bg="dark" data-bs-theme="dark">
                       
                        <Container>
                            <Navbar.Brand href="/"> <GrowExample/>Clandike</Navbar.Brand>
                            <Nav className="me-auto header-links">
                                <Nav.Link href="/">Лента</Nav.Link>
                                <Nav.Link href="/login">Выложить лабу</Nav.Link>
                                <Nav.Link href="/about">О нас</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                {/* <div className="logo">
                    <GrowExample/>
                    <span>Clandike</span>
                </div>
                <div className="links">
                    <nav>
                        <ul>
                            <li>
                                <a href="/">О нас</a>
                            </li>
                            <li>
                                <a href="/">Лента</a>
                            </li>
                            <li>
                                <a href="/profile">Опубликовать статью</a>
                            </li>
                        </ul>
                    </nav>
                </div> */}
            </div>
        </header>        
    )
}

export default Header;
