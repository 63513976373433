import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Header from '../components/home/Header'
import {TopicsList} from "../components/dashboard/topicsList";
import Badge from 'react-bootstrap/Badge';

const Dashboard = () => {
    axios.defaults.withCredentials = true;
    
    const navigate = useNavigate();
    const [subjects, setSubjects] = useState([]);
    const [topics, setTopics] = useState([]);


    const getSubjects = () => {
        axios.get('https://clandike.tech:5000/getSubjects')
            .then((res) => {
            console.log();
	    const subjects = res.data.subjects.sort((a, b) => a.name.localeCompare(b.name));
            setSubjects(subjects);
        })
        .catch();
    }
    
    useEffect(() => {
        axios.get('/').then(() => {})
        .catch(err => {
            if (err.response.status === 500)
                navigate('/error')
        }) 

        axios.get('https://clandike.tech:5000/api/v1/topics')
        .then((res)=> {
		getSubjects();
            setTopics(res.data);
        });

        axios.get('https://clandike.tech:5000/enter')
            .then()
            .catch(() => navigate('/login'))
    },[]);

    

    return (
        <div>
            <Header/>
            <div className="content-wrapper">
                <div className="main-content-block">
                    <div className="topics-block">
                        <div className="topics">
                            <nav>
                                <ul>
                                    {subjects.map(e => <li key={e.name} className=''><a href="/">{e.name}</a></li>)}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="content-topic-block">
                        <div className="header-block">
                            <h1>Популярные ресурсы <Badge bg="secondary">New</Badge></h1>
                        </div>
                        <div className="read-block">
                            <div className="list">
                                <TopicsList hierarchy={topics}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
