import axios from 'axios'
import Header from '../components/home/Header';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';


const Subject = () => {
    axios.defaults.withCredentials = true;
    const queryParameters = new URLSearchParams(window.location.search)
    const navigate = useNavigate();

    const id = queryParameters.get("id")
    const [content, setContent] = useState([]);
    const [name, setName] = useState([]);
    useEffect(()=>{
        axios.get('http://clandike.tech:5000/api/v1/'+id)
        .then((res)=> {
            setContent(res.data[0].content);
            setName(res.data[0].name);
        })
        .catch(err=>{
            navigate('/error');
        });
    });

    return (
        <div>
            <Header/>
            <div className="main-content">
                <div className="header-block">
                    <h2>{name}<Badge bg="secondary">TOP</Badge></h2>
                </div>
                <div className="content-block">
                    <p>
                        {content}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Subject;
